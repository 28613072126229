import logo from './logo.svg';
import './App.css';
import { Text, Box, Heading, Flex } from 'gestalt';
import 'gestalt/dist/gestalt.css';
import eggSpread from "./images/shutterstock_334221110.jpg";
import teaSpread from "./images/shutterstock_1039467721.jpg"
import charcuterieSpread from './images/shutterstock_1823342729.jpg';
import hummusSpread from './images/shutterstock_1906738693.jpg';

function App() {
  return (
    <Box padding={10}>
      <Heading color="purple" align="center">
        Dani's Table Spread Service
      </Heading>
      <Box paddingY={2}>
        <Text></Text>
      </Box>
      <Box width="100%" paddingY={10}>
        <Flex direction="row" justifyContent="between">
          <Box>
            <Flex direction="column" alignItems='center'>
              <img src={eggSpread} alt="Logo" width={300} height={200}></img>
              <Text weight="bold" size="lg">Breakfast Spreads</Text>
            </Flex>
          </Box>
          <Flex direction="column" alignItems='center'>
            <img src={teaSpread} alt="Logo" width={300} height={200}></img>
            <Text weight="bold" size="lg">Tea Spreads</Text>
          </Flex>
          <Box>
            <Flex direction="column" alignItems='center'>
              <img src={charcuterieSpread} alt="Logo" width={300} height={200}></img>
              <Text weight="bold" size="lg">Charcuterie Spreads</Text>
            </Flex>
          </Box>
          <Box>
            <Flex direction="column" alignItems='center'>
              <img src={hummusSpread} alt="Logo" width={300} height={200}></img>
              <Text weight="bold" size="lg">Mediterranean Spreads</Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default App;
